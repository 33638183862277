import React, { Component } from 'react'

import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion'

const ItemStyle = styled(Flex)`

    > :nth-child(1) {
        line-height: 0;
        width: 4rem;
        height: 4rem;

        svg {
            path {
                fill: #4975ba;
            }
        }
    }

    > :nth-child(2) {
        flex: 1;
        text-align: left;
        padding-left: 2rem;
    }

    font-size: 1.8rem;
    text-align: center;
    line-height: 2em;

    address,
    a,
    phone {
        margin: 0;
        display: block;
        font-style: normal;
        line-height: 1.2em;
    }

    a,
    span {
        text-align: center;
    }

    a {
        text-decoration: none;
        color: #555;
        @include BigText2;
        color: $blue2;

        &:hover {
            text-decoration: underline;
        }
    }

`;

const Item = ({ children }) =>
    <ItemStyle m={20} flexDirection="row" justifyContent="flex-start" alignItems="center">
        {children}
    </ItemStyle>

export default class Aside_ extends Component {
    render() {
        return (
            <Flex justifyContent="center" mt={20}>
                <Flex flexDirection={["column", "row"]} justifyContent="center" alignItems="flexStart">
                    <Flex flexDirection="column" justifyContent="center" alignItems="flexStart">
                        <Item>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M256 32c123.5 0 224 100.5 224 224S379.5 480 256 480 32 379.5 32 256 132.5 32 256 32m0-32C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm142.7 341.6l-1.4-4.4c-3.4-10-14.5-20.5-24.8-23.4l-37.8-10.3c-10.3-2.8-25 1-32.4 8.5l-13.7 13.7c-49.8-13.5-88.8-52.5-102.2-102.3l13.7-13.7c7.6-7.4 11.3-22 8.5-32.4l-10.3-37.8c-2.8-10.3-13.3-21.4-23.4-24.8l-4.3-1.4c-10-3.4-24.5 0-32 7.6L118 141.3c-3.7 3.6-6 14-6 14-.7 65 24.8 127.8 70.8 173.8 46 46 108.3 71.3 173.2 70.8.3 0 11-2.3 14.7-6l20.5-20.5c7.5-7.4 11-21.8 7.5-32z" />
                                </svg>
                            </span>
                            <span>+49 (0)34298-9889-0</span>
                        </Item>
                        <Item>
                            <span>
                                <svg viewBox="0 0 39.474 39.474">
                                    <path
                                        d="M38.347,4.825c0.619,0,1.127,0.508,1.127,1.127v27.571c0,0.619-0.508,1.127-1.127,1.127h-0.27H1.381
                                    h-0.27C0.492,34.649,0,34.141,0,33.522V5.952c0-0.619,0.492-1.127,1.111-1.127h0.27h0.317h0.587c11.825,0,23.65,0,35.49,0h0.302
                                    H38.347z M19.729,20.348l8.444-7.301l6.904-5.984h-0.032H34.49c-9.65,0-19.316,0-28.983,0h-0.54h-0.54H4.381l6.904,5.984
                                    L19.729,20.348z M29.633,14.729l-9.158,7.936c-0.413,0.365-1.048,0.381-1.476,0.016l-9.174-7.952L2.238,8.158v23.158l7.587-6.587
                                    l4.254-3.667c0.46-0.413,1.175-0.365,1.571,0.111c0.413,0.46,0.349,1.175-0.111,1.571l-4.254,3.682l-6.904,5.968h30.697
                                    l-6.904-5.968l-4.254-3.682c-0.46-0.397-0.508-1.111-0.111-1.571c0.413-0.476,1.111-0.524,1.571-0.111l4.254,3.667l7.603,6.587
                                    V8.158L29.633,14.729z"
                                    />
                                </svg>
                            </span>
                            <address>
                                Schillerstraße 86<br />
                04425 Taucha
                            </address>
                        </Item>
                    </Flex>
                    <Flex flexDirection="column" justifyContent="center" alignItems="flexStart">
                        <Item>
                            <span>
                                <svg viewBox="0 0 299.997 299.997">
                                    <path
                                        d="M149.996,0C67.157,0,0.001,67.158,0.001,149.997c0,82.837,67.156,150,149.995,150s150-67.163,150-150
                                            C299.996,67.158,232.835,0,149.996,0z M149.999,52.686l88.763,55.35H61.236L149.999,52.686z M239.868,196.423h-0.009
                                            c0,8.878-7.195,16.072-16.072,16.072H76.211c-8.878,0-16.072-7.195-16.072-16.072v-84.865c0-0.939,0.096-1.852,0.252-2.749
                                            l84.808,52.883c0.104,0.065,0.215,0.109,0.322,0.169c0.112,0.062,0.226,0.122,0.34,0.179c0.599,0.309,1.216,0.558,1.847,0.721
                                            c0.065,0.018,0.13,0.026,0.195,0.041c0.692,0.163,1.393,0.265,2.093,0.265h0.005c0.005,0,0.01,0,0.01,0
                                            c0.7,0,1.401-0.099,2.093-0.265c0.065-0.016,0.13-0.023,0.195-0.041c0.63-0.163,1.245-0.412,1.847-0.721
                                            c0.114-0.057,0.228-0.117,0.34-0.179c0.106-0.06,0.218-0.104,0.322-0.169l84.808-52.883c0.156,0.897,0.252,1.808,0.252,2.749
                                            V196.423z"
                                    />
                                </svg>
                            </span>
                            <a onClick={() => {
                                window.ga('send', 'event', { eventCategory: 'Contact', eventAction: 'E-Mail', eventLabel: 'Contact Page' });
                            }} href="mailto:info@balkonsysteme.info">info@balkonsysteme.info</a>
                        </Item>
                        <Item>
                            <span>

                            </span>

                        </Item>
                        {false && <Item>
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 485.23 485.23"
                                >
                                    <path d="M438.3 443.72l-32.16 32.17c-5.75 5.71-22.56 9.32-23.07 9.32a379.95 379.95 0 0 1-271.88-111.17A379.91 379.91 0 0 1 .03 101.28c0-.06 3.69-16.4 9.42-22.09L41.6 47C53.4 35.22 76 29.9 91.83 35.16l6.78 2.28c15.83 5.28 32.37 22.75 36.75 38.83l16.2 59.44c4.4 16.1-1.51 39.03-13.3 50.82l-21.5 21.5a228.1 228.1 0 0 0 160.49 160.54l21.5-21.5c11.79-11.79 34.77-17.68 50.87-13.3L409.07 350c16.05 4.35 33.53 20.87 38.83 36.72l2.25 6.78c5.27 15.84-.06 38.44-11.85 50.22zm46.92-379.4L420.89 0l-104.3 104.3-43.66-43.65v151.63h151.63l-43.65-43.65 104.3-104.3z" />
                                </svg>
                            </span>
                            <a>Rückruf anfordern</a>
                        </Item>}
                    </Flex>
                </Flex>
            </Flex>
        )
    }
}
