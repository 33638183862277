import React from 'react'

import SubPage from '../subPage'
import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text } from '@rebass/emotion'
import { css } from '@emotion/react'
import Aside from './Aside'
import Form from './Form'


const c = css`
    font-weight: 400;
    line-height: 2em;
    hyphens: auto;
    width: 90%;
    max-width: 70rem;
    margin: 2.5rem auto 0 auto;

    em {
        font-style: normal;
        font-weight: 400;
        hyphens: manual;
        color: #4975ba;
        border-bottom: 1px solid #4975ba;
    }

`

export default () =>
    <SubPage>
        <Box mx={[20, 50]} mb={[50, 100]}>
            <Flex justifyContent="center">
                <Box width={0.9}>
                    <Text color="#6f7c82" fontSize={[18, 18, 18, 20]} fontWeight={600} textAlign="center">So erreichen Sie uns</Text>
                    <Heading color="#292e31" fontSize={[30, 42, 46, 60]} fontWeight={600} textAlign="center">Kontakt</Heading>
                    <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={40} textAlign="center">
                        Rufen Sie uns jederzeit an dann können wir Sie zu Ihrem{' '}
                        <em>individuellen Angebot</em> gerne <em>Formular</em> beraten.
                    </Text>
                </Box>
            </Flex>

            <Box>
                <Aside />
                <Form />
            </Box>
        </Box>
    </SubPage>
