import React from 'react'

import Kontakt from '../components/kontakt'

import Layout from '../components/layout'
const Kontakt_ = () =>
    <Layout title="Kontakt">
        <Kontakt />
    </Layout>


export default Kontakt_